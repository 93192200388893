import './App.css';
import ComingSoon from './coming-soon/ComingSoon';

function App() {
  return (
   <ComingSoon />
  );
}

export default App;
