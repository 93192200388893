import './ComingSoon.css';

const ComingSoon = ()=>{
    return (
      <div className="App">
        <div className='imageContainer'></div>
      </div>
    );
  }

export default ComingSoon;
  